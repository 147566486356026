/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/


.App-header{
  max-height: 10vh;
}
.content{
  min-height: 80vh;
}
footer{
  max-height: 10vh;
}

h1{
  text-align: center;
}



button,.btn{
  background: black;
  padding: 7px;
  margin: 0 auto;
  color: white !important;
  border-radius: 5px;
  min-width: 320px;
  display: inline-block;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 200ms ease;
}

.btn:hover{
  background-color: darkgray;
  color: #ffffff;
}

a:hover{
  text-decoration: underline;
}

.subtitle{
  cursor: pointer;
}