/* polices*/

@font-face {
    font-family: oswald;
    src: url(../fonts/oswald/Oswald-Bold.ttf)
}
@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-master/RobotoCondensed-Regular.ttf)
}
@font-face {
    font-family: walkway;
    src: url(../fonts/Arual.ttf);
}
img {
    max-width: 100%;
    height: auto
}
.banner {
    margin: 30px 0
}
section h2 {
    color: #636363
}
body {
    font-family: Roboto;
}
h1 {
    font-family: oswald;
    font-size: 3rem;
    text-transform: uppercase
}
.gyo-header h1 {
    margin: 25px 0;
    font-family:Oswald;
}
.icon-hamburger {
    display: none;
}
p {
    font-family: Roboto
}

.content{
    padding: 15px;

}

figure.logo{
    padding: 0;
    margin: 0;
}

.gyo-navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.gyo-navigation ul {
    list-style: none;
    /*background:linear-gradient(white,#dadada);*/

    text-align: right
}
.gyo-navigation ul li {
    list-style: none;
    display: inline-block;
    padding: 0 15px;
}
.gyo-navigation ul li a {
    display: flex;
    text-decoration: none;
    color: #000000;
    

}
.gyo-navbar{
    padding-left: 0;
    margin-left: 0;
    text-transform: uppercase;

}



.gyo-navbar > li > a:not(.active){
color: lightgray;
}
/*legal-notice*/

.mentions {
    padding-left: 50px;
    float: left;
    width: 1024px;
    background-color: #333;
    height: 48px;
    font-family: Verdana, Geneva, sans-serif;
    padding-top: 3px;
}
.informations {
    float: left;
    margin-top: 50px;
    font-family: Verdana, Geneva, sans-serif, ;
    height: 100px;
    padding-top: 20px;
    padding-left: 5px;
    width: 256px;
    border-color: #666;
    border-style: solid;
}
.ssinformations {
    margin-top: 50px;
    font-family: Verdana, Geneva, sans-serif, ;
    height: auto;
    padding-top: 20px;
    padding-left: 5px;
    float: left;
    width: 256px;
    border-color: #666;
    border-style: solid;
    float: left;
}
.box {
    width: 280px;
    float: left;
    margin-left: 50px;
    height: 720px
}
.jpego {
    width: 694px;
    height: 768px;
    text-align: center;
    float: right;
    padding-top: 50px;
}
/*prestations*/

#prestations{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;   
}

#prestations article > ul{
    min-height: 110px;
    padding-left: 0;
}


#prestations div article {
    display: inline-block;
    width: 32%;
    vertical-align: top
}
#prestations div article,
form {
    text-align: center
}
#prestations div article p {
    padding: 15px 0;
    text-align: normal;
	font-weight:bold;
}
#prestations article a{
	 color:inherit;
	 text-decoration:none;
}
#prestations ul li{
	list-style:none;
	padding-bottom:5px
}
#prestations ul li a{
	text-decoration:none;
	color:inherit
}


.prestation-item{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 33.33%;
    height: 70vh;
    padding: 15px;
    text-align: center;
    box-sizing: border-box;
    
}
.prestation-item img{
    max-width: 33.33%;
    margin: 0 auto;
    
}

.wrap > section > header > h1,
#portfolio header {
    text-align: center
}
#portfolio header h2 {
    margin: 0;
    color: #636363;
    width: auto;
    display: inline
}
#portfolio header h2 a {
    color: #636363;
    text-decoration: none;
}
#portfolio header {
    margin-bottom: 15px;
}
.portfolio-item {
    width: 33%;
    display: inline-block;
    text-align: center;
    margin-bottom: 15px;;
}
.portfolio-item img {
    max-width: 100%;
    height: auto
}
/*profile*/

.banner-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 0 auto;
    width: 33%;
}
.w-50{
    max-width: 50%;
    height: auto;
}
.banner-article-layout {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%
}

.txt-citation{
    display: block;
    font-size: 1.5rem;
    margin-bottom: 15px;

}

 #about-quotation{
    text-align: initial;
    position: absolute;
    bottom: 20%;
    left: 100%;
    width: 60%;
 }
.gyo-header {
    text-align: center;
}
#main {
    margin: 80px auto;
    position: relative;
    width: 40px
}
#about-navigation {
    position: fixed;
    vertical-align: top;
    padding: 15px;
    top: 30%;
}




#about-navigation li {
    list-style: none;
    padding-bottom: 15px;
}
/*formulaire de contact*/

#form1{
    width: 50%;
    margin: 0 auto;
}

.gyo-form-control {
    display: block;
}
.gyo-form-control * {
    vertical-align: middle;
}
.gyo-form-control label {
    width: 10%;
}
.gyo-form-control input,
.gyo-form-control textarea,
.gyo-form-control select {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #636363;
    width: 30%;
    margin: 15px;
}
.gyo-form-control input,
.gyo-form-control label {
    display: inline-block;
    vertical-align: middle;
}

.gyo-form-control .send-button{
    max-width: 20%;
}

.gyo-form-control .send-button:hover{
    border-color: darkgray;
}
.gyo-form-control .reset-button{
    max-width: 20%;
}
.success-msg{
	color:green;
}
.send-button{
	font-size:1.1rem;
	background:#000000;
	color:#ffffff;
	font-weight:bold;
	font-family:Roboto;
}
.reset-button{
	background:#ffffff;
	font-size:1.1rem;
	font-weight:bold;
	font-family:Roboto;
}
/*portfolio*/

.item-caption {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    width: 100%;
    z-index: 200;
    right: 0;
    height: 20%;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    -moz-transition: all ease 0.4s;
    top: 100%;
}

.subtitle {
    color: #CCC;
}

.subtitle.active{
    color: black;
}

.portfolio-item {
    position: relative;
    bottom: 40%;
    overflow: hidden;
}
.portfolio-item:hover .item-caption {
    top: 70%;
}
/*footer*/

.gyo-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 15px;
    max-height: 10vh;
}
.gyo-footer a {
	text-decoration:none;
	color:inherit;
}
.gyo-footer a:hover {
	text-decoration:underline;

}

/*top link*/
.top_link::before{
		content:'↑';
			color:#ffffff;
			font-size:5rem;
			font-weight:bold;
				font-family:arial;
				vertical-align:middle;
				margin:0;
}
.top_link{
	text-decoration:none;
	vertical-align:middle;
border-radius:5px;
	background:rgba(0,0,0,0.2);
	font-family:corbel;
	color:#ffffff;
	content:' ';
	padding:15px;
	position: fixed;
	right:0;
	top:45%;
	z-index:200;
	cursor:pointer;
	-webkit-transition:background 800ms ease;
	-moz-transition:background 800ms ease;
	transition:background 800ms ease;
}
.top_link:hover{
	background-color:grey;

}
/*animations*/

.a_leftToRight {
    animation: leftToRight 500ms ease normal;
    -webkit-animation: leftToRight 500ms ease normal;
    -moz-animation: leftToRight 500ms ease normal;
    animation: leftToRight 500ms ease normal;
    position: relative;
}
.a_fadeIn {
    animation: fadeIn 1.5s linear normal;
    -webkit-animation: fadeIn 1.5s linear normal;
    -moz-animation: fadeIn 1.5s linear normal;
    animation: fadeIn 1.5s linear normal;
}
.a_rightToLeft {
    animation: rightToLeft 500ms ease normal;
    -webkit-animation: rightToLeft 500ms ease normal;
    -moz-animation: rightToLeft 500ms ease normal;
    animation: rightToLeft 500ms ease normal;
    position: relative;
}



@-webkit-keyframes rightToLeft {
    0% {
        left: -300%;
        opacity: 0;
    }
    25% {
        left: -75%;
    }
    50% {
        left: -50%;
    }
    75% {
        left: -25%;
    }
    100% {
        left: 0%;
    }
}
@keyframes rightToLeft {
    0% {
        left: -100%;
    }
    25% {
        left: -75%;
    }
    50% {
        left: -50%;
    }
    75% {
        left: -25%;
    }
    100% {
        left: 0%;
    }
}
@-moz-keyframes rightToLeft {
    0% {
        left: -100%;
    }
    25% {
        left: -75%;
    }
    50% {
        left: -50%;
    }
    75% {
        left: -25%;
    }
    100% {
        left: 0%;
    }
}
@-webkit-keyframes rightToLeft {
    0% {
        left: -300%;
        opacity: 0;
    }
    25% {
        left: -75%;
    }
    50% {
        left: -50%;
    }
    75% {
        left: -25%;
    }
    100% {
        left: 0%;
    }
}
@keyframes leftToRight {
    0% {
        right: -100%;
    }
    25% {
        right: -75%;
    }
    50% {
        right: -50%;
    }
    75% {
        right: -25%;
    }
    100% {
        left: 0%;
    }
}
@-moz-keyframes leftToRight {
    0% {
        right: -100%;
    }
    25% {
        right: -75%;
    }
    50% {
        right: -50%;
    }
    75% {
        right: -25%;
    }
    100% {
        right: 0%;
    }
}
@-moz-keyframes fadeIn {
    0% {
 
        opacity: 0%;
    }
    25% {
        opacity: 25%;
    }
    50% {
        opacity: 50%;
    }
    75% {
        opacity: 75%;
    }
    100% {
        opacity: 100%;
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0%;
    }
    25% {
        opacity: 25%;
    }
    50% {
        opacity: 50%;
    }
    75% {
        opacity: 75%;
    }
    100% {
        opacity: 100%;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75
    }
    100% {
        opacity: 1;
    }
}
/*responsive design*/

@media screen and (min-width: 768px) {
    /*contact*/

    #form1{
        width: auto;
    }



}


@media screen and (max-width: 640px) {
    
    .content{
        margin-top: 30px;
    }

    /*Prestations*/

    #prestations{
        flex-direction: column;
        width: auto;
    }

    .prestation-item{
        width: 100%;
        justify-content: center;
        height: auto;
    }
    
    #prestations div article {
        display: block;
        width: 100%;
        align-items: middle;
        padding-bottom: 30px;
    }

    .gyo-navigation > figure{
        margin: 0;
    }
    .gyo-navigation ul li {
        display: block;
        padding: 0 5px
    }

    .gyo-navigation{
        height: 60px;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        margin: 0;
        padding: 0;
        background: black;
        color: white;
        z-index: 500;
    }
    .gyo-navbar{
        margin: 0;
    }
    .gyo-navbar > li {
        margin-bottom: 15px;
    }
    .nav-block{
            
            position: absolute;
            left: 0;
            top: 60px;
            width:0;
            height: 0;
            background-color: rgba(0,0,0,0.8);
     }
     .nav-block.collapsed{
        position: absolute;
        width:100%;
        height: 100vh;
    }  

     .nav-block.collapsed > ul{
        transform: translateX(0);

     }  

     .nav-block ul > li> a{
        color: white;
     }   

     .nav-block > ul{
        width: 65vw;
        overflow: hidden;
        height: 100vh;
        padding: 15px;
        background: #000000;
        text-align: initial;
        transform: translateX(-100%);
        transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
     } 

    .icon-hamburger {
        display: block;
        padding: 7px;
    }
    .wrap section {
        text-align: center;
    }

    .gyo-navigation ul li {
        padding: 5px 0
    }
    img {
        max-width: 100%;
        height: auto
    }
    h2 {
        font-size: 0.98rem
    }
    h1 {
        font-size: 2rem
    }
    /*profile*/

    #about-navigation{
        position: initial;
    }
     #about-quotation{
        position: relative;
        width: auto;
        left: auto;
     }

     .banner-article {
        position: relative;
        width: auto;
     }
     .banner-article-layout {
        height: auto;
     }
    #about-navigation a:hover {
        background-position: 0
    }
    #about-navigation a:hover span {
        width: auto;
        padding: 0;
        overflow: visible;
    }
    #about-navigation,
    #profile-quotation {
        display: flex;
        width: 100%;
        padding: 0;
    }
    #about-navigation li {
        width: 100%
    }
    #about-navigation li {
        padding-left: 5px;
    }
    #about-navigation li a span {
        width: 100%;
        padding: 0;
        position: normal;
        -webkit-transition: none;
        -moz-transition: none;
        transition: none;
        margin-left: 10px;
    }
    #profil-navigation span {
        text-decoration: none
    }
    a {
        text-decoration: none
    }
    #about-navigation .about span,
    #about-navigation .Cv span,
    #about-navigation .Fb span,
    #about-navigation .Tw span,
    #about-navigation .ow span {
        position: relative;
        background-color: white;
        overflow: normal;
        width: auto;
        font-family: oswald;
    }

    .portfolio-type{
        padding: 0;
    }

    /*contact*/

    #form1{
        width: auto;
        margin: 0 auto;
        width: auto;
    }

    .gyo-form-control label {
        width: auto;
    }
    .gyo-form-control {
        text-align: initial;
        position: relative;
    }
    .gyo-form-control input,
    .gyo-form-control label {
        display: block;
        position: relative;
        max-width: 100%
    }
    .gyo-form-control input,
    .gyo-form-control textarea,
    .gyo-form-control select {
        border-radius: 5px;
        padding: 5px 0;
        border: 1px solid #636363;
        width: 100%;
        margin: 15px 0;
    }
}

@media screen and (max-width:1024px){
    /*global*/
    .btn{
       min-width: auto;
    }
    .prestation-item{
        justify-content: space-around;
    }
    /*profile*/
    #about-quotation{
        position: initial;
        width: auto;
    }
    .logo img{
        max-width: 70%;
    }
    
}
@media screen and (min-width: 768px) {

    .mobile{
        display: none !important;
    }

}

@media screen and (max-width: 767px) {

    .only-desktop{
        display: none !important;
    }

}




#portfolio-drawings,#print-drawings{
  padding: 15px;
}
.portfolio-type li{
    list-style: none;
    display: inline-block; padding: 0 15px;
}

.portfolio-type{
    display: block;
    text-align: center;
}


.title-illustrations h2{
  margin:15px 0 !important;
}


.about img.w-50{

    max-width: 45px !important;
}